<template>
  <app-select v-on="$listeners" v-bind="$attrs" hide-details="auto" dense :item-text="(val) => val.short_name"
    :items="filteredEmployees" :value-comparator="comparator" :item-value="(val) => val" />
  <!-- return-object -->
</template>

<script>
export default {
  props: {
    roleFilter: {
      type: String,
      default: null,
    },
  },

  data: () => ({
    employees: [],
  }),

  computed: {
    filteredEmployees() {
      if (this.roleFilter) {
        return this.employees.filter(employee => employee.position === this.roleFilter);
      }
      return this.employees;
    },
  },

  created() {
    this.select();
  },

  methods: {

    comparator(a, b) {
      return a?.id == b?.id;
    },

    async select() {
      let response = await this.$http.index("employee/employee-report");
      this.employees = response.employees;
    },
  },
};
</script>

<style></style>