<template>
  <v-alert
    v-if="showAlert"
    border="left"
    dense
    outlined
    prominent
    text
    type="error"
  >
    Existe
    <b>
      {{ pending }}
    </b>
    transferência{{ plural }} de estoque pendente{{ plural }}
    <template v-slot:append>
      <v-btn @click.stop="clickToStockTransfer()" color="info" small icon>
        <app-icon small>north_east </app-icon>
      </v-btn>
    </template>
  </v-alert>
</template>

<script>
export default {
  data() {
    return {
      pending: 0,
    };
  },

  created() {
    this.select();
  },

  computed: {
    plural() {
      return this.pending > 1 ? "s" : "";
    },
    showAlert() {
      return this.pending > 0 && this.$acl.can("stockTransferIndex");
    },
  },

  methods: {
    select() {
      this.$http
        .index("bi/stock-transfer")
        .then((response) => {
          this.pending = response.pending;
        })
        .catch((error) => {});
    },
    clickToStockTransfer() {
      this.$router.push({
        name: "stockTransferIndex",
        query: { status: "pending" },
      });
    },
  },
};
</script>

<style>
</style>