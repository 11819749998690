<template>
  <app-icon :color="types[type].color">{{ types[type].icon }}</app-icon>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      required: true
    }
  },

  data : () => ({
    types : {
      delivery : {
        icon : 'local_shipping',
        color : 'primary'
      },
      pickup : {
        icon : 'local_mall',
        color : 'success'
      },
    }
  }),
}  
</script>

<style>

</style>